import React, { useEffect, useState } from "react";
import { Box, Container, Grid, makeStyles, TextField, Typography, Button, FormControl, InputLabel, Select, Collapse } from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { createDifunt, getTanatoris } from "../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../components/Title";
import Thumb from "../../../components/Thumb";
import moment from "moment";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingTop: 47,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	formControl: {
		marginTop: 16,
		marginBottom: 15,
	},
	wrap: {
		padding: 20,
		borderRadius: 5,
		backgroundColor: "white",
		boxShadow: "-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)",
	},
}));

const DifuntApp = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [tanatoris, setTanatoris] = useState();
	const [salesValides, setSalesValides] = useState(3);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const get = async () => {
			const { tanatoris } = await getTanatoris();
			setTanatoris(tanatoris);
		};

		get();
	}, []);

	const crear = async (values) => {
		setLoading(true);
		const { message, difunt } = await createDifunt(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setLoading(false);
		if (values.publicat) {
			// navigate("/");
			navigate("/difunt/" + difunt.slug);
		} else {
			navigate(-1, { state: values });
		}
	};

	const onChangeTanatori = (e, setFieldValue) => {
		const { value } = e.target;
		setFieldValue("tanatori_id", value);
		if (value !== "1000" && value !== "0") {
			let t = tanatoris.find((item) => item.id === parseInt(value));
			// setFieldValue("sala", 1);
			setSalesValides(t?.sales);
		} else {
			setFieldValue("sala", "");
		}
	};

	return (
		<Page className={classes.root} title={"Crear necrològica"}>
			<Container maxWidth={false} className={classes.container}>
				<Grid container>
					<Grid item md={9} xs={12}>
						<Formik
							initialValues={{
								nom: "",
								cognoms: "",
								data_naixement: "",
								data_defuncio: moment().format("YYYY-MM-DD"),
								sala: "",
								tanatori_id: "",
								tanatori_extern: "",
								horari_tanatori: "",
								desti: "",
								cerimonia: "",
								dia_cerimonia: "",
								hora_cerimonia: "",
								imatge: "",
								publicat: false,
								viudu: "",
								sobrenom: "",
							}}
							validationSchema={Yup.object().shape({
								nom: Yup.string().required("El nom és obligatòri"),
								cognoms: Yup.string().required("Els cognoms són obligatoris"),
								data_defuncio: Yup.string().required("La data de defunció és obligatòria"),
								sala: Yup.number()
									.min(1, "Ha de ser mínim 1")
									.max(salesValides, "Ha de ser maxim " + salesValides),
							})}
							onSubmit={(values) => {
								crear(values);
							}}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Grid container>
										<Grid item md={6}>
											<Box mb={3}>
												<Title title={"Crear necrològica"} subtitle={"Entra les dades per guardar una nova necrològica"} />
											</Box>
										</Grid>
									</Grid>

									<Box className={classes.wrap}>
										<Grid container spacing={3}>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.nom && errors.nom)}
													fullWidth
													helperText={touched.nom && errors.nom}
													label="Nom"
													margin="normal"
													name="nom"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.nom}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.cognoms && errors.cognoms)}
													fullWidth
													helperText={touched.cognoms && errors.cognoms}
													label="Cognoms"
													margin="normal"
													name="cognoms"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.cognoms}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.data_naixement && errors.data_naixement)}
													fullWidth
													helperText={touched.data_naixement && errors.data_naixement}
													label={"Data Naixement"}
													margin="normal"
													name="data_naixement"
													onBlur={handleBlur}
													onChange={handleChange}
													type="date"
													InputProps={{
														inputProps: {
															max: moment().format("YYYY-MM-DD"),
														},
													}}
													value={values.data_naixement}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.data_defuncio && errors.data_defuncio)}
													fullWidth
													helperText={touched.data_defuncio && errors.data_defuncio}
													label={"Data Defunció"}
													margin="normal"
													name="data_defuncio"
													onBlur={handleBlur}
													onChange={handleChange}
													InputProps={{
														inputProps: {
															max: moment().format("YYYY-MM-DD"),
														},
													}}
													type="date"
													value={values.data_defuncio}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
										</Grid>

										<Grid container spacing={3}>
											<Grid item md={3} xs={12}>
												<FormControl className={classes.formControl} fullWidth>
													<InputLabel htmlFor="tanatori_id">Tanatòri</InputLabel>
													<Select
														native
														value={values.tanatori_id}
														onChange={(e) => {
															onChangeTanatori(e, setFieldValue);
														}}
														onBlur={handleBlur}
														label="Tanatori"
														inputProps={{
															name: "tanatori_id",
															id: "tanatori_id",
														}}
													>
														<option value={0} key={0}></option>
														{tanatoris?.map((tanatori) => {
															return (
																<option value={tanatori.id} key={tanatori.id}>
																	{tanatori.nom}
																</option>
															);
														})}
														<option value={1000} key={1000}>
															Extern
														</option>
													</Select>
												</FormControl>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.sala && errors.sala)}
													fullWidth
													helperText={touched.sala && errors.sala}
													label={"Sala"}
													margin="normal"
													name="sala"
													InputProps={{
														inputProps: {
															min: 1,
															max: salesValides,
														},
													}}
													onBlur={handleBlur}
													onChange={handleChange}
													type="number"
													value={values.sala}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField
													error={Boolean(touched.horari_tanatori && errors.horari_tanatori)}
													fullWidth
													helperText={touched.horari_tanatori && errors.horari_tanatori}
													label={"Horari Tanatori"}
													margin="normal"
													name="horari_tanatori"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.horari_tanatori}
												/>
											</Grid>
										</Grid>
										<Collapse in={values.tanatori_id === "1000"}>
											<Grid container spacing={3}>
												<Grid item xs={12}>
													<TextField
														error={Boolean(touched.tanatori_extern && errors.tanatori_extern)}
														fullWidth
														helperText={touched.tanatori_extern && errors.tanatori_extern}
														label={"Nom Tanatori Extern (Manual)"}
														margin="normal"
														name="tanatori_extern"
														onBlur={handleBlur}
														onChange={handleChange}
														type="text"
														value={values.tanatori_extern}
													/>
												</Grid>
											</Grid>
										</Collapse>
										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<TextField
													error={Boolean(touched.cerimonia && errors.cerimonia)}
													fullWidth
													helperText={touched.cerimonia && errors.cerimonia}
													label={"Lloc Cerimònia"}
													margin="normal"
													name="cerimonia"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.cerimonia}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.dia_cerimonia && errors.dia_cerimonia)}
													fullWidth
													helperText={touched.dia_cerimonia && errors.dia_cerimonia}
													label={"Dia Cerimònia"}
													margin="normal"
													name="dia_cerimonia"
													onBlur={handleBlur}
													onChange={handleChange}
													type="date"
													value={values.dia_cerimonia}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.hora_cerimonia && errors.hora_cerimonia)}
													fullWidth
													helperText={touched.hora_cerimonia && errors.hora_cerimonia}
													label={"Hora Cerimònia"}
													margin="normal"
													name="hora_cerimonia"
													onBlur={handleBlur}
													onChange={handleChange}
													type="time"
													value={values.hora_cerimonia}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={5} xs={12}>
												<TextField
													error={Boolean(touched.desti && errors.desti)}
													fullWidth
													helperText={touched.desti && errors.desti}
													label={"Destí"}
													margin="normal"
													name="desti"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.desti}
												/>
											</Grid>
											<Grid item md={4} xs={12}>
												<TextField
													error={Boolean(touched.viudu && errors.viudu)}
													fullWidth
													helperText={touched.viudu && errors.viudu}
													label={"Víuda/Vidu"}
													margin="normal"
													name="viudu"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.viudu}
												/>
												<Typography style={{ fontSize: 12 }}>Autocompletar:</Typography>
												<IconButton style={{ borderRadius: 50 }} onClick={() => setFieldValue("viudu", "Vidu de")}>
													<Typography style={{ fontSize: 12 }}>M</Typography>
												</IconButton>

												<IconButton style={{ borderRadius: 50 }} onClick={() => setFieldValue("viudu", "Vídua de")}>
													<Typography style={{ fontSize: 12 }}>F</Typography>
												</IconButton>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.sobrenom && errors.sobrenom)}
													fullWidth
													helperText={touched.sobrenom && errors.sobrenom}
													label={"Sobrenom"}
													margin="normal"
													name="sobrenom"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.sobrenom}
												/>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item md={12} xs={12}>
												<Box mt={3}>
													<Typography>Imatge</Typography>
													<input
														id="imatge"
														name="imatge[]"
														type="file"
														onChange={(event) => {
															setFieldValue("imatge", event.currentTarget.files[0]);
														}}
														className="form-control"
													/>
												</Box>
												<Box p={2}>
													<Thumb file={values.imatge} />
												</Box>
											</Grid>
										</Grid>
									</Box>

									<Box my={2} display={"flex"}>
										<Button color="primary" fullWidth size="large" type="submit" variant="contained" disabled={loading}>
											{"Crear"}
										</Button>
										<Box m={1} />
										<Button
											color="secondary"
											fullWidth
											size="large"
											type="submit"
											variant="contained"
											disabled={loading}
											onClick={() => setFieldValue("publicat", true)}
										>
											{"Crear i publicar"}
										</Button>
									</Box>
								</form>
							)}
						</Formik>
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
};

export default DifuntApp;
