import React, { useEffect, useState } from "react";
import { CircularProgress, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import "moment/locale/ca";
import { Button } from "@material-ui/core";
import { editVivo } from "../../../../database/API";
import { useSnackbar } from "notistack";
import { Dialog } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { useForm } from "react-hook-form";
import CustomTextField from "../../../../components/CustomTextField";
import moment from "moment";

const DialogEditVivoRecuerdo = ({ open, setOpen, difunt, setVivoApi }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	console.log(difunt);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		// resolver: yupResolver(schemaInscripcioAdmin),
		mode: "onTouched",
	});

	useEffect(() => {
		const setValuesForm = () => {
			reset(difunt);

			setValue("fecha_defuncion", moment(difunt.fecha_defuncion, "DD/MM/YYYY").format("YYYY-MM-DD"));
			setValue("fecha_nacimiento", moment(difunt.fecha_nacimiento, "DD/MM/YYYY").format("YYYY-MM-DD"));
			setValue("fecha_ceremonia", difunt.fecha_ceremonia ? moment(difunt.fecha_ceremonia, "DD/MM/YYYY").format("YYYY-MM-DD") : "");
			setValue("fecha_fin_servicio", difunt.fecha_fin_servicio ? moment(difunt.fecha_fin_servicio, "DD/MM/YYYY").format("YYYY-MM-DD") : "");
		};
		open && setValuesForm();
	}, [open]);

	const crearVivo = async (values) => {
		if (constructForm(values, difunt).length === 0) {
			enqueueSnackbar("No hi han canvis", {
				variant: "error",
			});
		} else {
			setLoading(true);
			const { message, success, data } = await editVivo(constructForm(values, difunt).data, difunt.id);
			if (success) {
				setVivoApi(data);
				setOpen(false);
				enqueueSnackbar(message, {
					variant: "success",
				});
			} else {
				enqueueSnackbar("Dades incorrectes. Comprova els camps", {
					variant: "error",
				});
			}
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg">
			<form onSubmit={handleSubmit(crearVivo)}>
				<DialogTitle id="alert-dialog-title">{"Editar difunt a Vivo Recuerdo"}</DialogTitle>
				<DialogContent>
					<Grid container spacing={3}>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="nombre"
								label="Nom"
								errors={errors.name}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="apellidos"
								label="Cognoms"
								errors={errors.cognoms}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="fecha_nacimiento"
								label="Data naixement"
								type={"date"}
								errors={errors.data_naixement}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="fecha_defuncion"
								label="Data defunció"
								errors={errors.data_defuncio}
								type={"date"}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={3}>
							<FormControl fullWidth required>
								<InputLabel htmlFor="tipus">Sala</InputLabel>
								<Select
									{...register("nombre_sala")}
									fullWidth
									label={"Nom sala"}
									error={Boolean(errors?.nombre_sala)}
									defaultValue={difunt?.nombre_sala}
									defaultChecked={difunt?.nombre_sala}
								>
									<MenuItem selected value={"Sala 1"}>
										Sala 1
									</MenuItem>
									<MenuItem value={"Sala 2"}>Sala 2</MenuItem>
								</Select>
								<Typography variant="caption" color="#e32420">
									{errors?.nombre_sala?.message}
								</Typography>
							</FormControl>
						</Grid>
						<Grid item md={6}>
							<CustomTextField
								register={register}
								name="texto_adicional"
								label="Horari tanatori"
								errors={errors.texto_adicional}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="sobrenombre"
								label="Sobrenom"
								errors={errors.sobrenom}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="fecha_ceremonia"
								label="Dia cerimònia"
								type={"date"}
								errors={errors.dia_cerimonia}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="hora_ceremonia"
								label="Hora cerimònia"
								type="time"
								errors={errors.hora_cerimonia}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="fecha_fin_servicio"
								label="Dia final cerimònia"
								type={"date"}
								errors={errors.fecha_fin_servicio}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="hora_fin_servicio"
								label="Hora final cerimònia"
								type="time"
								errors={errors.hora_fin_servicio}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>

						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="lugar_ceremonia"
								label="Lloc cerimònia"
								errors={errors.cerimonia}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid>

						{/* <Grid item md={3}>
							<CustomTextField
								register={register}
								name="poblacion_ceremonia"
								label="Població cerimònia"
								errors={errors.poblacion_ceremonia}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid> */}
						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="nombre_opcional_cementerio_crematorio"
								label="Destí"
								errors={errors.nombre_opcional_cementerio_crematorio}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid>
						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="localidad"
								label="Localitat de defunció"
								errors={errors.localidad}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>

						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="lugar_nacimiento"
								label="Lloc naixement"
								errors={errors.lugar_nacimiento}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="provincia_pais_nacimiento"
								label="Província o país de naixement"
								errors={errors.provincia_pais_nacimiento}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>

						<Grid item md={4}>
							<FormControl fullWidth displayEmpty>
								<InputLabel htmlFor="tipus">Cementiri/Crematori</InputLabel>
								<Select
									{...register("cementerio_crematorio")}
									fullWidth
									label={"Cementori/Crematori"}
									error={Boolean(errors?.cementerio_crematorio)}
									defaultChecked={difunt?.cementerio_crematorio}
									defaultValue={difunt?.cementerio_crematorio}
								>
									<MenuItem value={"cementerio"}>Cementiri</MenuItem>
									<MenuItem value={"crematorio"}>Crematori</MenuItem>
								</Select>
								<Typography variant="caption" color="#e32420">
									{errors?.cementerio_crematorio?.message}
								</Typography>
							</FormControl>
							{/* <CustomTextField
								register={register}
								name="cementerio_crematorio"
								label="Cementiri o crematòri"
								errors={errors.cementerio_crematorio}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/> */}
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="tlf_admin_1"
								label="Telèfon administrador 1"
								errors={errors.tlf_admin_1}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="tlf_admin_2"
								label="Telèfon administrador 2"
								errors={errors.tlf_admin_2}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="tlf_admin_3"
								label="Telèfon administrador 3"
								errors={errors.tlf_admin_3}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="tlf_admin_4"
								label="Telèfon administrador 4"
								errors={errors.tlf_admin_4}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)} className={classes.eliminar}>
						Cancel·lar
					</Button>
					{loading ? (
						<Button disabled className={classes.cancelar}>
							<CircularProgress color="white" style={{ width: 25, height: 25 }} />
						</Button>
					) : (
						<Button type="submit" autoFocus className={classes.cancelar}>
							Editar a Vivo Recuerdo
						</Button>
					)}
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default DialogEditVivoRecuerdo;

const useStyles = makeStyles((theme) => ({
	imatge: {
		width: "100%",
		aspectRatio: 1 / 1,
		height: "auto",
	},
	column: {
		backgroundColor: "white",
		borderRadius: 5,
		padding: 30,
		boxShadow: "-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)",
	},
	nom: {
		textAlign: "center",
		marginBottom: 20,
	},
	eliminar: {
		backgroundColor: theme.palette.danger.main,
		color: "white",
		marginTop: 20,
		"&:hover": {
			backgroundColor: theme.palette.danger.light,
		},
	},
	cancelar: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginTop: 20,
	},
}));

function constructForm(values, difunt) {
	const data = new FormData();

	console.log(values, difunt);

	if (moment(values.fecha_defuncion).format("DD/MM/YYYY") !== difunt.fecha_defuncion) {
		data.append("fecha_defuncion", moment(values.fecha_defuncion).format("DD/MM/YYYY"));
	}
	if (values.localidad !== difunt.localidad) {
		data.append("localidad", values.localidad);
	}
	if (values.nombre !== difunt.nombre) {
		data.append("nombre", values.nombre);
	}
	if (values.apellidos !== difunt.apellidos) {
		data.append("apellidos", values.apellidos);
	}
	if (values.sobrenombre !== difunt.sobrenombre) {
		data.append("sobrenombre", values.sobrenombre === "" ? "!clear" : values.sobrenombre);
	}
	if (moment(values.fecha_nacimiento).format("DD/MM/YYYY") !== difunt.fecha_nacimiento) {
		data.append("fecha_nacimiento", moment(values.fecha_nacimiento).format("DD/MM/YYYY"));
	}
	if (values.lugar_nacimiento !== difunt.lugar_nacimiento) {
		data.append("lugar_nacimiento", values.lugar_nacimiento);
	}
	if (values.provincia_pais_nacimiento !== difunt.provincia_pais_nacimiento) {
		data.append("provincia_pais_nacimiento", values.provincia_pais_nacimiento);
	}
	if (moment(values.fecha_ceremonia).format("DD/MM/YYYY") !== difunt.fecha_ceremonia) {
		data.append("fecha_ceremonia", values.fecha_ceremonia ? moment(values.fecha_ceremonia).format("DD/MM/YYYY") : "!clear");
	}
	if (values.lugar_ceremonia !== difunt.lugar_ceremonia) {
		data.append("lugar_ceremonia", values.lugar_ceremonia === "" ? "!clear" : values.lugar_ceremonia);
	}
	if (values.poblacion_ceremonia !== difunt.poblacion_ceremonia) {
		data.append("poblacion_ceremonia", values.poblacion_ceremonia === "" ? "!clear" : values.poblacion_ceremonia);
	}
	if (moment(values.hora_ceremonia, "HH:mm:ss").format("HH:mm") !== difunt.hora_ceremonia) {
		data.append("hora_ceremonia", values.hora_ceremonia ? moment(values.hora_ceremonia, "HH:mm:ss").format("HH:mm") : "!clear");
	}
	if (values.cementerio_crematorio !== difunt.cementerio_crematorio) {
		data.append("cementerio_crematorio", values.cementerio_crematorio === "" ? "!clear" : values.cementerio_crematorio);
	}
	if (values.poblacion_cementerio_crematorio !== difunt.poblacion_cementerio_crematorio) {
		data.append("poblacion_cementerio_crematorio", values.poblacion_cementerio_crematorio === "" ? "!clear" : values.poblacion_cementerio_crematorio);
	}
	if (values.nombre_opcional_cementerio_crematorio !== difunt.nombre_opcional_cementerio_crematorio) {
		data.append(
			"nombre_opcional_cementerio_crematorio",
			values.nombre_opcional_cementerio_crematorio === "" ? "!clear" : values.nombre_opcional_cementerio_crematorio
		);
	}

	if (values.texto_adicional !== difunt.texto_adicional) {
		data.append("texto_adicional", values.texto_adicional === "" ? "!clear" : values.texto_adicional);
	}
	if (moment(values.fecha_fin_servicio).format("DD/MM/YYYY") !== difunt.fecha_ceremonia) {
		data.append("fecha_fin_servicio", values.fecha_fin_servicio ? moment(values.fecha_fin_servicio).format("DD/MM/YYYY") : "!clear");
	}
	if (moment(values.hora_fin_servicio, "HH:mm:ss").format("HH:mm") !== difunt.hora_fin_servicio) {
		data.append("hora_fin_servicio", values.hora_fin_servicio ? moment(values.hora_fin_servicio, "HH:mm:ss").format("HH:mm") : "!clear");
	}

	if (values.tlf_admin_1 !== difunt.tlf_admin_1) {
		data.append("tlf_admin_1", values.tlf_admin_1);
	}
	if (values.tlf_admin_2 !== difunt.tlf_admin_2) {
		data.append("tlf_admin_2", values.tlf_admin_2 === "" ? "!clear" : values.tlf_admin_2);
	}
	if (values.tlf_admin_3 !== difunt.tlf_admin_3) {
		data.append("tlf_admin_3", values.tlf_admin_3 === "" ? "!clear" : values.tlf_admin_3);
	}
	if (values.tlf_admin_4 !== difunt.tlf_admin_4) {
		data.append("tlf_admin_4", values.tlf_admin_4 === "" ? "!clear" : values.tlf_admin_4);
	}
	if (values.tlf_admin_5 !== difunt.tlf_admin_5) {
		data.append("tlf_admin_5", values.tlf_admin_5 === "" ? "!clear" : values.tlf_admin_5);
	}

	for (var pair of data.entries()) {
		console.log(pair);
	}
	var length = Array.from(data.entries(), ([key, prop]) => ({
		[key]: {
			ContentLength: typeof prop === "string" ? prop.length : prop.size,
		},
	})).length;

	return { data, length };
}
